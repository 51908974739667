<template>
  <main class="index-page">
    <!-- <nuxt-link to="/signin"><button>Enter</button></nuxt-link> -->

    <section v-for="(block, i) in blocks" :key="`block-${i}`" :id="`block${i}`">
      <div class="wrapper">
        <!-- <div :style="`background-image:url(/static/${block.img}${isDesktop ? '' : '-mobile'}.webp)`" class="img" /> -->
        <div class="images">
          <a :href="`/static/${block.img}.webp`" target="_blank" rel="noopener noreferrer">
            <img :src="`/static/${block.img}${isDesktop ? '' : '-mobile'}.webp`" :alt="block.h1">
          </a>
        </div>
        <div class="body">
          <div class="slider-dots">
            <div @click="goTo('block0')" :class="{ active: blockId === 'block0' }" class="item"></div>
            <div @click="goTo('block1')" :class="{ active: blockId === 'block1' }" class="item"></div>
            <div @click="goTo('block2')" :class="{ active: blockId === 'block2' }" class="item"></div>
            <div @click="goTo('block3')" :class="{ active: blockId === 'block3' }" class="item"></div>
          </div>
          
          <div class="tagline">
            <h1 v-if="i === 0">{{ block.h1 }}</h1>
            <h2 v-else>{{ block.h1 }}</h2>
          </div>
          <div class="tagdesc" v-html="block.desc">
          </div>
          <div class="toaction">
            <nuxt-link to="/signin" tag="button">{{ block.btn }}</nuxt-link>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="wrapper">
        <h2>
          <nuxt-link to="/articles">Статьи обзор-рецензии</nuxt-link>
        </h2>
        <Articles :items="articles" />
      </div>
    </section>

    <section>
      <div class="wrapper">
        <div class="footer">
          <div>
            support@silverpixel.ru
          </div>
          <div>
            <div style="display: inline-block;">
              <!-- Top.Mail.Ru logo -->
              <a href="https://top-fwz1.mail.ru/jump?from=3589492">
              <img src="https://top-fwz1.mail.ru/counter?id=3589492;t=410;l=1" height="31" width="88" alt="Top.Mail.Ru" style="border:0;" /></a>
              <!-- /Top.Mail.Ru logo -->
            </div>
            <div id="liveinternet" style="display: inline-block;">
              <!--LiveInternet counter--><a href="https://www.liveinternet.ru/click"
              target="_blank"><img id="licnt4208" width="31" height="31" style="border:0" 
              title="LiveInternet"
              src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAIBTAA7"
              alt=""/></a><!--/LiveInternet-->
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mapState } from 'vuex'

export default {
  layout: 'clean',
  middleware: 'authToCatalog',
  head() {
    const title = `Совместный просмотр фильмов и видео онлайн — Смотрите вместе с друзьями!`
    const description = `Смотрите фильмы и видео онлайн вместе с друзьями или семьей в реальном времени! Совместный просмотр с синхронизацией, чатом и удобным интерфейсом — создавайте комнаты и наслаждайтесь общением даже на расстоянии.`
    const keys = `совместный просмотр фильмов, онлайн-кинотеатр для друзей, смотреть фильмы вместе, синхронное воспроизведение видео, чат для просмотра фильмов, совместные просмотры онлайн, смотреть кино онлайн с друзьями`
    
    return {
      title: title,
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: 'https://silverpixel.ru'
        }
      ],
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: description
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: keys,
        },
      ]
    }
  },
  components: {
    // SH: () => import('~/components/SH.vue'),
    // Slider: () => import('~/components/Slider.vue'),
    // StatsSlider: () => import('~/components/StatsSlider.vue'),
    Articles: () => import('~/components/Articles.vue'),
  },
  computed: mapState(['isDesktop']),
  async asyncData({ $axios }) {
    const { data } = await $axios.get('/api/articles?page=1')
    return {
      articles: data,
      blockId: 'block0',
      blocks: [
        {
          img: 'screen-1',
          h1: 'Смотрите фильмы и видео вместе, где бы вы ни находились!',
          desc: 'Синхронизированное воспроизведение, чат и возможность обсуждения с друзьями онлайн',
          btn: 'Присоединиться',
        },
        {
          img: 'screen-2',
          h1: 'Как это работает?',
          desc: `
<ul>
  <li><strong>Создание комнаты:</strong> Простой и понятный процесс создания комнаты для просмотра.</li>
  <li><strong>Приглашение друзей:</strong> Удобный способ поделиться ссылкой.</li>
  <li><strong>Совместный просмотр:</strong> Возможность совместного воспроизведения с опцией паузы, перемотки и комментирования.</li>
</ul>`,
          btn: 'Начать бесплатно',
        },
        {
          img: 'screen-3',
          h1: 'Преимущества и особенности',
          desc: `
<ul>
  <li><strong>Синхронность:</strong> Сервис сохраняет синхронность видео для всех участников.</li>
  <li><strong>Чат и реакция:</strong> Возможность общения во время просмотра.</li>
  <li><strong>Поддержка видео- и аудио-стриминга:</strong> Поддержка различных платформ или интеграции (YouTube, Netflix и др.).</li>
  <li><strong>Мобильное приложение:</strong> Устанавливайте приложение для более удобного пользовательского опыта.</li>
</ul>`,
          btn: 'Создать комнату',
        },
        {
          img: 'screen-4',
          h1: 'Отзывы пользователей',
          desc: `
<ul class="reviews">
  <li>
    <strong>Анна, 26 лет</strong>
    <p>Обожаю этот сервис! Я и мои друзья теперь каждую пятницу собираемся в нашей виртуальной комнате, и это почти как раньше, когда мы все были в одном городе! Потрясающая идея — смотреть и обсуждать фильмы вместе, даже если нас разделяют сотни километров. Спасибо создателям, это настоящее спасение!</p>
  </li>
  <li>
    <strong>Игорь, 34 года</strong>
    <p>Совместный просмотр с друзьями никогда не был таким удобным! Даже когда все заняты, всегда можно собраться на пару часов, посмотреть сериал или матч, обсудить всё в чате... и так здорово, что все синхронизировано! Настоящий кайф для киноманов!</p>
  </li>
  <li>
    <strong>Марина, 42 года</strong>
    <p>Как же это круто! Раньше думала, что онлайн-встречи не заменят реальных, но здесь атмосфера почти та же. Особенно приятно обсуждать моменты фильма прямо в процессе, делиться эмоциями, смеяться вместе! Это просто находка для меня и моей сестры, живущей в другом городе.</p>
  </li>
  <li>
    <strong>Алексей, 19 лет</strong>
    <p>Смотреть фильмы с друзьями стало в разы веселее! Все вместе реагируем на смешные моменты, комментируем героев, и даже не замечаешь, что ты у себя дома. Мы залипаем каждый день, не могу поверить, как круто это работает!</p>
  </li>
  <li>
    <strong>Вера, 57 лет</strong>
    <p>Мои дети разъехались, но мы теперь каждую неделю можем собираться на семейные кинопросмотры! Я, конечно, не так хорошо разбираюсь в технологиях, но здесь всё настолько просто, что даже мне удобно. Это так трогательно – видеть реакции всех на экране. Отличный сервис для связи с близкими!</p>
  </li>
  <li>
    <strong>Сергей, 29 лет</strong>
    <p>Этот сайт изменил наши вечеринки! Теперь каждый может участвовать, даже если не может приехать. Пару кликов – и мы уже вместе. Идеально для выходных, когда хочется расслабиться и повеселиться. Сервис – бомба!</p>
  </li>
</ul>
          `,
          btn: 'Попробовать',
        },
      ],
      blockTimeout: null,
    }
  },
  mounted() {
    document.querySelector('.index-page').addEventListener('scroll', this.detectBlock)

    this.innerLI()
  },
  methods: {
    innerLI() {
      const script = document.createElement('script')
      script.innerHTML = `(function(d,s){d.getElementById("licnt4208").src=
"https://counter.yadro.ru/hit?t50.1;r"+escape(d.referrer)+
((typeof(s)=="undefined")?"":";s"+s.width+"*"+s.height+"*"+
(s.colorDepth?s.colorDepth:s.pixelDepth))+";u"+escape(d.URL)+
";h"+escape(d.title.substring(0,150))+";"+Math.random()})
(document,screen)`

      if (document.getElementById('liveinternet')) {
        document.getElementById('liveinternet').append(script)
      }
    },
    detectBlock(event) {
      clearTimeout(this.blockTimeout)

      const blocksPosTop = []

      this.blockTimeout = setTimeout(() => {
        const doc = document.documentElement.clientHeight

        for(let i = 0; i < 4; i++) {
          const block = document.getElementById(`block${i}`)

          const visible = doc - block.getBoundingClientRect().top

          if (visible > 0) {
            blocksPosTop.push(visible)

            this.blockId = `block${blocksPosTop.length - 1}`
          }
        }
      })
    },
    goTo(blockId) {
      this.blockId = blockId
      const block = document.getElementById(blockId)
      if (block) block.scrollIntoView({ behavior: 'smooth', })
    },
  },
}
</script>

<style src="~/assets/index.styl" lang="stylus" />
